
















































































































































































@use "sass:math";
@import '@design';

.toolbar .v-toolbar {
  z-index: 3;
}

.header {
  background-color: $color-navbar;
}
.nav-left,
.nav-right {
  flex-basis: 50%;
}

.nav-route {
  display: inline-block;
  margin: 0 math.div($size-grid-padding, 2);
  text-transform: uppercase;
  a {
    // TODO (ESS) : globalize fonts
    font-family: 'Asap', sans-serif;
    font-weight: 300;
    color: black;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  &.BaseButton a {
    color: $color-button-text;
  }
  &.social-icon {
    flex: 0 1 auto;
    img {
      transition: opacity 0.2s ease;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
  &.router-link-active a {
    padding-bottom: 0.4em;
    color: $color-link-text;
    text-decoration: none;
    cursor: default;
    border-bottom: 2px solid $color-db-purple;
  }
} // nav-route

.nav-logo {
  max-width: 97px;
  cursor: pointer;
  &.small {
    max-width: 60px;
  }
}

@media only screen and (max-width: 414px) {
  .toolbar .v-toolbar__content {
    padding: 0 5px;
  }
}
